import _ from 'lodash'
import { productHasError } from '../components/Cart/cartFunctions'
import { calculateFinalProductPrice } from '../components/Shop/productPageFunctions'
import { formatSingleProduct } from '../helpers/productPageFormatter'

const initialState = {
  product: {},
  templateFields: {},
  configurableSelection: {},
  artworkSelection: {},
  digitalProofingSelection: {
    // mapped by product
    // [bundle_group_product_id]: {
    //     digital_proofing_product_id: 1
    //  },
  },
  errors: {
    minQty: false,
    maxQty: false,
    incrQty: false,
    template: false,
    variants: false,
    configSelection: false,
    digitalProofing: false,
    stock: false
  },

  product_loading: false,
  cart_items_loading: []
}

export default (state = initialState, action, topState) => {
  let bundle_group_product_id
  let bundleProduct

  switch (action.type) {
    // update bundle products
    case 'ASSIGN_BUNDLE_PRODUCT':
      const existingBundleProducts = state.product.bundle.products
      const bundleGroup = action.payload.bundleGroup
      const incomingProduct = { ...action.payload.product, bundle_group_id: bundleGroup.bundle_group_id }
      const selectionExistsForThisGroup = !!existingBundleProducts.find(
        prod => bundleGroup.bundle_group_id === prod.bundle_group_id
      )

      let newBundleProducts = [...state.product.bundle.products]

      // for radio groups, only one product allowed per group ID'
      if (bundleGroup.bundle_group_type === 'radio_group') {
        // if there is a selection, remove and replace with incoming
        if (selectionExistsForThisGroup) {
          newBundleProducts = _.filter(newBundleProducts, prod => bundleGroup.bundle_group_id !== prod.bundle_group_id)
        }
        newBundleProducts.push(incomingProduct)
      } else if (bundleGroup.bundle_group_type === 'select_group') {
        // for select groups, toggle incoming product IDs per group
        if (selectionExistsForThisGroup) {
          // if this product is already there, "de-select" it by removing it
          const productCurrentlyExistingInBundle = existingBundleProducts.find(
            prod =>
              prod.bundle_group_product_id === incomingProduct.bundle_group_product_id &&
              prod.bundle_group_id === bundleGroup.bundle_group_id
          )
          if (productCurrentlyExistingInBundle) {
            // selection for this group includes the incoming group product.
            // meaning we need to de-select it.
            // filter products that are not the incoming one
            newBundleProducts = _.filter(newBundleProducts, prod => {
              return !(
                incomingProduct.bundle_group_id === prod.bundle_group_id &&
                incomingProduct.bundle_group_product_id === prod.bundle_group_product_id
              )
            })
          } else {
            newBundleProducts.push(incomingProduct)
          }
        } else {
          newBundleProducts.push(incomingProduct)
        }
      } else {
        // bundle_group_type should be simple_group here
        // payload will be array of products - all the products in the simple group
        const incomingProductArray = action.payload.product
        newBundleProducts = [...newBundleProducts, ...incomingProductArray]
      }
      newBundleProducts = _.orderBy(newBundleProducts, 'bundle_group_id')

      return {
        ...state,
        product: {
          ...state.product,
          bundle: {
            ...state.product.bundle,
            products: [...newBundleProducts]
          }
        }
      }
    // to reset state
    case 'CLEAR_PRODUCT':
      // return initial state
      return {
        product: {},
        templateFields: {},
        configurableSelection: {},
        artworkSelection: {},
        digitalProofingSelection: {},
        errors: {
          minQty: false,
          maxQty: false,
          incrQty: false,
          template: false,
          variants: false,
          artwork: false
        },

        product_loading: false,
        cart_items_loading: []
      }

    // fetches single product
    case 'FETCH_PRODUCT_DETAILS':
      const formattedProduct = formatSingleProduct(action.payload)
      const productWithPriceData = {
        ...formattedProduct,
        priceData: calculateFinalProductPrice(
          formattedProduct,
          formattedProduct.quantity,
          {},
          {},
          action.payload.cartItems
        )
      }
      return { ...state, product: productWithPriceData }
    case 'UPDATE_PRODUCT_DETAILS':
      return {
        ...state,
        product: Object.assign({}, state.product, action.payload.data)
      }

    // handles template field input
    case 'UPDATE_PRODUCT_TEMPLATE_FIELD':
      const id = action.payload.template_field_id || action.payload.id
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          [id]: action.payload
        }
      }

    // handles template field input for bundles
    case 'UPDATE_BUNDLE_TEMPLATE_FIELD':
      const template_field = action.payload.template_field
      bundle_group_product_id = action.payload.bundle_group_product_id
      const existingTemplateDataForThisProduct = state.templateFields[bundle_group_product_id]
        ? state.templateFields[bundle_group_product_id]
        : {}

      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          [bundle_group_product_id]: {
            ...existingTemplateDataForThisProduct,
            [template_field.template_field_id]: template_field
          }
        }
      }

    // handles selection for a bundle configurable
    case 'UPDATE_CONFIG_SELECTION':
      const configSelectionData = action.payload

      // config selections are mapped by bundle_group_product_id
      bundle_group_product_id = configSelectionData.bundle_group_product_id

      const existingProductSelectionData = state.configurableSelection[bundle_group_product_id]
        ? state.configurableSelection[bundle_group_product_id]
        : { selected_options: {} }
      // select the bundle product for which we are making a selection,
      // because we need to know the quantity for price calculation
      bundleProduct = state.product.bundle.products.find(
        prod => prod.bundle_group_product_id === bundle_group_product_id
      )

      return {
        ...state,

        configurableSelection: {
          ...state.configurableSelection,

          [bundle_group_product_id]: {
            ...existingProductSelectionData,

            quantity: bundleProduct.quantity,
            product_id: configSelectionData.product_id,
            bundle_group_product_id: bundle_group_product_id,
            selected_options: {
              ...existingProductSelectionData.selected_options,

              [configSelectionData.variant_id]: configSelectionData.option
            }
          }
        }
      }

    case 'UPDATE_ARTWORK_SELECTION':
      const artworkSelectionData = action.payload

      // config selections are mapped by bundle_group_product_id
      bundle_group_product_id = artworkSelectionData.bundle_group_product_id

      const existingArtworkSelectionData = state.artworkSelection[bundle_group_product_id]
        ? state.artworkSelection[bundle_group_product_id]
        : { selected_options: {} }
      // select the bundle product for which we are making a selection,
      // because we need to know the quantity for price calculation
      bundleProduct = state.product.bundle.products.find(
        prod => prod.bundle_group_product_id === bundle_group_product_id
      )
      return {
        ...state,

        artworkSelection: {
          ...state.artworkSelection,

          [bundle_group_product_id]: {
            ...existingArtworkSelectionData,

            quantity: bundleProduct.quantity,
            product_id: artworkSelectionData.product_id,
            bundle_group_product_id: bundle_group_product_id,
            selected_options: [
              // commenting below out, because only one artwork can be selected for a product
              // ...existingArtworkSelectionData.selected_options,
              artworkSelectionData.artwork
            ]
          }
        }
      }

    case 'UPDATE_DIGITAL_PROOFING_SELECTION':
      const dpSelectionData = action.payload

      // DP selections are mapped by bundle_group_product_id
      bundle_group_product_id = dpSelectionData.bundleProduct.bundle_group_product_id
      const proofingPreview = dpSelectionData.proofingPreview

      //
      return {
        ...state,

        digitalProofingSelection: {
          ...state.digitalProofingSelection,

          [bundle_group_product_id]: {
            proofingPreview: proofingPreview
          }
        }
      }

    case 'SET_PRODUCT_PAGE_ERRORS':
      const errorObject = action.payload
      // merge passed in errors with existing
      const newErrors = Object.assign({}, state.errors, errorObject)
      return {
        ...state,
        errors: newErrors
      }

    case 'PRODUCT_LOADING':
      return { ...state, product_loading: true }
    case 'CLEAR_PRODUCT_LOADING':
      return { ...state, product_loading: false }

    case 'CART_ITEM_LOADING':
      return { ...state, cart_items_loading: [...state.cart_items_loading, action.payload] }
    case 'CLEAR_CART_ITEM_LOADING':
      return { ...state, cart_items_loading: [..._.without(state.cart_items_loading, action.payload)] }
    case 'ADD_SKU_TO_BUNDLE_PRODUCT':
      const incomingBundleProduct = action.payload.bundleProduct
      const bundleProducts = state.product.bundle.products
      const bundleProductToChange = bundleProducts.find(
        bp => bp.bundle_group_product_id === incomingBundleProduct.bundle_group_product_id
      )
      bundleProductToChange.product_full_sku = action.payload.fullProductSku
      return {
        ...state,
        product: {
          ...state.product,
          bundle: {
            ...state.product.bundle,
            products: [...bundleProducts]
          }
        }
      }
    case 'ADD_PRODUCT_SKU_ID_TO_PRODUCT':
      if (action.bundleGroupProductId) {
        let products = [...state.product.bundle.products]
        const productIndex = products.findIndex(
          product => product.bundle_group_product_id === action.bundleGroupProductId
        )
        if (productIndex !== -1) {
          products[productIndex] = { ...products[productIndex], product_sku_id: action.payload }
        }

        return {
          ...state,
          product: {
            ...state.product,
            bundle: {
              ...state.product.bundle,
              products: products
            }
          }
        }
      } else {
        return { ...state, product: { ...state.product, product_sku_id: action.payload } }
      }
    default:
      return state
  }
}
