// EMBRYO ACTIONS
export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST'
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST'
export const MOVE_WISHLISTITEM_TO_CART = 'MOVE_WISHLISTITEM_TO_CART'
export const FINAL_PAYMENT = 'FINAL_PAYMENT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY'
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const RTL_LAYOUT = 'RTL_LAYOUT'
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR'
export const DELETE_USER = 'DELETE_USER'
export const ADD_NEW_USER = 'ADD_NEW_USER'

// SHERWOOD ACTIONS
export const FETCH_ABOUT_TEMPLATE = 'FETCH_ABOUT_TEMPLATE'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCT_DETAILS_SKU = 'FETCH_PRODUCT_DETAILS_SKU'
export const FETCH_PORTAL_CATEGORY_PRODUCTS = 'FETCH_PORTAL_CATEGORY_PRODUCTS'
export const FETCH_PORTAL_CATEGORIES = 'FETCH_PORTAL_CATEGORIES'
export const FETCH_PORTAL_FEATURED_CATEGORIES = 'FETCH_PORTAL_FEATURED_CATEGORIES'
export const FETCH_PORTAL_FEATURED_PRODUCTS = 'FETCH_PORTAL_FEATURED_PRODUCTS'
export const FETCH_PRODUCT_SUGGESTIONS = 'FETCH_PRODUCT_SUGGESTIONS'
export const SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY'
export const SAVE_PRODUCT_SUGGESTIONS = 'SAVE_PRODUCT_SUGGESTIONS'

// USERS
export const CREATE_USER = 'CREATE_USER'
export const FETCH_USER = 'FETCH_USER'
export const VERIFY_PASSWORD_TOKEN_SUCCESS = 'VERIFY_PASSWORD_TOKEN_SUCCESS'
export const VERIFY_PASSWORD_TOKEN_FAILURE = 'VERIFY_PASSWORD_TOKEN_FAILURE'

// ERRORS
export const CREATE_SESSION_ERROR = 'CREATE_SESSION_ERROR'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const CREATE_CART_ERROR = 'CREATE_CART_ERROR'
export const PRODUCT_ERROR = 'PRODUCT_ERROR'

// SUCCESS
export const CREATE_SUCCESS_MESSAGE = 'CREATE_SUCCESS_MESSAGE'

// CART
export const FETCH_PRODUCT_BUNDLE_SELECTIONS = 'FETCH_PRODUCT_BUNDLE_SELECTIONS'
export const FETCH_TAX = 'FETCH_TAX'
export const CREATE_ORDER = 'CREATE_ORDER'
export const FETCH_PRODUCT_DETAILS = 'FETCH_PRODUCT_DETAILS'
export const FETCH_BUNDLE = 'FETCH_BUNDLE'
export const ADD_PRODUCT_SKU_ID_TO_PRODUCT = 'ADD_PRODUCT_SKU_ID_TO_PRODUCT'

// DELIVERY ADDRESSES
export const CREATE_DELIVERY_ADDRESS = 'CREATE_DELIVERY_ADDRESS'
export const UPDATE_DELIVERY_ADDRESS = 'UPDATE_DELIVERY_ADDRESS'
export const DELETE_DELIVERY_ADDRESS = 'DELETE_DELIVERY_ADDRESS'
export const FETCH_SMART_DELIVERY_ADDRESSES = 'FETCH_SMART_DELIVERY_ADDRESSES'
export const FETCH_SMART_DELIVERY_ADDRESSES_ERROR = 'FETCH_SMART_DELIVERY_ADDRESSES_ERROR'
export const CLEAR_SMART_DELIVERY_ADDRESSES_ERROR = 'CLEAR_SMART_DELIVERY_ADDRESSES_ERROR'
export const CART_MULTIPLE_SMART_DELIVERY_ADDRESSES = 'CART_MULTIPLE_SMART_DELIVERY_ADDRESSES'

// ORDERS
export const FETCH_CLIENT_ORDERS = 'FETCH_CLIENT_ORDERS'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const STOCK_CHECK_BULK_FAILURE = 'STOCK_CHECK_BULK_FAILURE'
export const FETCH_ORDER_INVOICE = 'FETCH_ORDER_INVOICE'
export const SET_NET_TERM = 'SET_NET_TERM'
export const SET_NET_TERM_PO_NUMBER = 'SET_NET_TERM_PO_NUMBER'

//SHIPPING QUOTE
export const FETCH_SHIPPING_QUOTE_ERROR = 'FETCH_SHIPPING_QUOTE_ERROR'
export const CREATE_CODE_ERROR = 'CREATE_CODE_ERROR'
export const CREATE_CODE_SUCCESS = 'CREATE_CODE_SUCCESS'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'
export const CREATE_APPLIED_PROMOTION = 'CREATE_APPLIED_PROMOTION'
export const CREATE_ORDER_FALLBACK = 'CREATE_ORDER_FALLBACK'
export const REMOVE_FALLBACK = 'REMOVE_FALLBACK'
export const CLEAR_PROMOTIONS = 'CLEAR_PROMOTIONS'
export const CREATE_UPDATED_CART = 'CREATE_UPDATED_CART'
export const CLEAR_TAX_ADJUSTMENTS = 'CLEAR_TAX_ADJUSTMENTS'
export const CLEAR_CODE_ERRORS = 'CLEAR_CODE_ERRORS'
export const TAX_ERROR = 'TAX_ERROR'
export const CLEAR_TAX_ERROR = 'CLEAR_TAX_ERROR'
export const FETCH_ORDER_DETAILS = 'FETCH_ORDER_DETAILS'
export const CLEAR_UPDATED_CART = 'CLEAR_UPDATED_CART'
export const PROOFING_PREVIEW = 'PROOFING_PREVIEW'
