import React from 'react'
import _ from 'lodash'
import { Button, Row, Input } from 'reactstrap'
import { connect } from 'react-redux'
import dollar_price from '../general/DollarPrice'
import ProductVariantSelectCss from './ProductVariantSelect.css'
import { indefinite } from '../../util/utils'

const ProductVariantSelect = props => {
  const { selectedOptionsByVariantId, errors, product, currentLocation } = props
  const variants = product.variants

  const extraPrice = cents => {
    const value = dollar_price(cents)
    if (cents > 0) {
      return <> + {value}</>
    } else if (cents < 0) {
      return <> - {value}</>
    } else {
      return ''
    }
  }

  // returns true if user has selected an option for a given variant id
  const selectionMade = variant_id => !!selectedOptionsByVariantId[variant_id]

  const getOptionExtraPrice = option => {
    const priceModifierData = option.price_modifier
    if (priceModifierData) {
      return extraPrice(priceModifierData.price_modifier_cents)
    }
    return null
  }

  return (
    <div>
      {variants.map((variant, i) => {
        let extraPrice
        const selectedOption = selectedOptionsByVariantId[variant.variant_id]
        if (selectedOption && selectedOption.has_price_modifier === true) {
          extraPrice = getOptionExtraPrice(selectedOption)
        }

        // for variants using buttons/swatch
        if (variant.display_type === 'Button' || variant.display_type === 'Swatch') {
          return (
            <Row className="variant-options p0 ml-0 mb-20" key={i}>
              <div className="config-option ml-0 w-100">
                <div>
                  {!selectionMade(variant.variant_id) ? (
                    <b>Please select {indefinite(variant.display_name ? variant.display_name : variant.name)}</b>
                  ) : (
                    <>
                      <b>{variant.display_name ? variant.display_name : variant.name}: </b>
                      {selectedOption.name}
                      {props.currentLocation.show_price ? extraPrice : null}
                    </>
                  )}
                </div>

                {variant.nested_data.map((option, index) => {
                  const isVariantOptionSelected = () => {
                    if (selectedOptionsByVariantId[variant.variant_id]) {
                      return selectedOptionsByVariantId[variant.variant_id].id === option.id
                    } else {
                      return false
                    }
                  }

                  let conditionalStyle = {}
                  // modify background color if hex is white
                  let patt = /#*[fF]{3,6}/gm
                  if (patt.test(option.css_hex)) {
                    conditionalStyle = {
                      border: '2px solid #cccccc'
                    }
                  } else {
                    conditionalStyle = {
                      border: '2px solid #ffffff'
                    }
                  }

                  return (
                    <React.Fragment key={index}>
                      <Button
                        id={option.id}
                        value={option.id}
                        onClick={event => props.selectVariant(event, variant)}
                        fontSize="50%"
                        className={
                          isVariantOptionSelected()
                            ? variant.display_type === 'Swatch'
                              ? 'is-swatch selected-variant-is-swatch'
                              : 'is-button selected-variant-is-button'
                            : variant.display_type === 'Swatch'
                              ? 'is-swatch'
                              : 'is-button'
                        }
                        style={{
                          color: option.css_hex ? option.css_hex : '#333333',
                          backgroundColor: variant.display_type === 'Swatch' ? option.css_hex : '#e4e4e4',
                          backgroundImage: option.icon.thumb.url ? `url(${option.icon.thumb.url})` : null,
                          textDecoration: 'none',
                          textAlign: 'center',
                          display: 'inline-block',
                          margin: '5px 6px 5px 0px',
                          padding: 'inherit',
                          ...conditionalStyle
                        }}
                      >
                        {variant.display_type === 'Button' ? option.name : ''}
                      </Button>
                    </React.Fragment>
                  )
                })}
              </div>
            </Row>
          )
        } else {
          return (
            <div key={i} className="config-option ml-0">
              <label>{variant.display_name ? variant.display_name : variant.name}</label>
              <Input
                type="select"
                id={`${variant.variant_id}`}
                index={i}
                className="w-100"
                onChange={event => props.selectVariant(event, variant)}
                value={
                  selectedOptionsByVariantId[variant.variant_id]
                    ? selectedOptionsByVariantId[variant.variant_id].id
                    : -1
                }
              >
                <option disabled value="-1">
                  Please Select
                </option>
                {variant.nested_data.map((option, index) => {
                  let extraPrice = null
                  if (option.has_price_modifier === true) {
                    extraPrice = getOptionExtraPrice(option)
                  }
                  return (
                    <option key={index} value={option.id}>
                      {option.name}
                      {props.currentLocation.show_price ? extraPrice : null}
                    </option>
                  )
                })}
              </Input>
            </div>
          )
        }
      })}
      <hr className="w-100 ml-0" />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation
  }
}

export default connect(mapStateToProps)(ProductVariantSelect)
