import { mapDispatchToProps } from '../../productPageFunctions'
import { connect } from 'react-redux'
import { Col, Input, Row } from 'reactstrap'
import dollar_price from '../../../general/DollarPrice'

const Configurable = ({ product, updateConfigSelection, configSelection, currentLocation }) => {
  const configurableVariants = product.nested_data.filter(el => !!el.variant_id)

  return (
    <Row>
      {/* for each variant, render its input field */}
      {configurableVariants.map((variant, i) => {
        // define onChange for inputs
        const handleChange = e => {
          // event will be option ID
          const option_id = parseInt(e.target.value, 10)
          const variant_id = variant.variant_id

          // select the option by the passed in option ID
          const selectedOption = variant.nested_data.find(e => e.id === option_id)

          // send the necessary data to redux
          updateConfigSelection({
            product_id: product.product_id,
            bundle_group_product_id: product.bundle_group_product_id,
            variant_id: variant_id,
            option: selectedOption
          })
        }

        const selectionsForThisProduct = configSelection[product.bundle_group_product_id]
          ? configSelection[product.bundle_group_product_id].selected_options
          : {}

        return (
          <Col className="mt-1" key={i} xs="12" md="6">
            <b>{variant.display_name ? variant.display_name : variant.name}</b>
            <Input
              className="mt-1 mb-0"
              size="sm"
              key={i}
              type="select"
              value={
                selectionsForThisProduct[variant.variant_id] ? selectionsForThisProduct[variant.variant_id].id : '-1'
              }
              onChange={handleChange}
            >
              <option value="-1" disabled>
                Please select...
              </option>

              {/* Options */}
              {variant.nested_data &&
                variant.nested_data.map((option, i) => {
                  let optionInputLabel = option.name
                  if (option.has_price_modifier === true && currentLocation.show_price) {
                    const additionalPriceCents = option.price_modifier.price_modifier_cents

                    optionInputLabel = (
                      <>
                        {optionInputLabel} + {dollar_price(additionalPriceCents)}
                      </>
                    )
                  }

                  return (
                    <option key={i} value={option.id}>
                      {optionInputLabel}
                    </option>
                  )
                })}
            </Input>
          </Col>
        )
      })}
    </Row>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    configSelection: state.productPage.configurableSelection,
    currentLocation: state.currentLocation
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Configurable)
