import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'
import { createRoot } from 'react-dom/client'
import { store } from './store'
import App from './App'
import LoadingSpinner from './components/general/LoadingSpinner'
import {
  REACT_APP_STORE_AUTH0_DOMAIN,
  REACT_APP_STORE_AUTH0_CLIENT_ID,
  REACT_APP_STORE_AUTH0_REDIRECT_URI
} from './constants'

const appRootContainer = document.getElementById('root')
const root = createRoot(appRootContainer)

const auth0Domain = REACT_APP_STORE_AUTH0_DOMAIN
const auth0ClientId = REACT_APP_STORE_AUTH0_CLIENT_ID
const currentUrl = window.location.href
const auth0RedirectUri = currentUrl.replace(/log-in$/, 'auth0_callback')

root.render(
  <Provider store={store}>
    <Auth0Provider domain={auth0Domain} clientId={auth0ClientId} redirect_uri={auth0RedirectUri}>
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <App />
        </Suspense>
      </Router>
    </Auth0Provider>
  </Provider>
)

// unregister()
